
/* eslint-disable */
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FileUpload from "@/components/scope/file-upload.vue";
import Editor from "@common/src/components/scope/editor/index.vue";
import { Table } from "@/utils/decorator";
import { getImageSize, emptyToLine } from "@/utils/common";
import dayjs from "dayjs";
import { isMoney, isPositiveIntegerZero } from "@/utils/verify";
import t from "@common/src/i18n/t";
import { MessageBox } from "element-ui";

const app = namespace("app");
const store = namespace("store");
const campaign = namespace("campaign");
const coupon = namespace("coupon");
const fission = namespace("fission");
const base = namespace("base");
const marketing = namespace("marketing");
const management = namespace("management");
const userMember = namespace("userMember");

@Component({
  components: { FileUpload, Editor }
})
@Table("loadListData", { pageSize: 5 })
export default class CollectCustomerEdit extends Vue {
  @store.State storeData;
  @campaign.Action createSaveCampaign;
  @campaign.Action queryCampaignDetail;
  @base.Action getDictionaryList;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  @coupon.Action queryList;
  @app.Mutation setPBPX;
  @marketing.Action queryCardTemplateList;
  @marketing.Action newActivitySave;        //活动保存
  @marketing.Action newActivityDetail;      //活动详情
  @fission.Action insertFission;
  @fission.Action queryFissionCampaign;
  @fission.Action updateFission;
  @management.Action getStoreList;
  @management.Action getStoreCouponList;
  @userMember.Action getMemberLevelInfo;
  @userMember.Action updateMemberLevelInfo;
  @userMember.Action addMemberLevelInfo;

  emptyToLine = emptyToLine;

  memberLevelFormData = {
    id: null, //主键Id
    levelId: null, //等级id
    level: null, //会员等级
    levelName: "", //等级名称
    beginGrowth: null, //起始成长值
    endGrowth: null, //终止成长值
    smallLevelLogo:null, //等级小图标
    levelLogo: null, // 等级logo
    levelBack:null, // 等级背景
    levelDetailLogo:null,//等级详情logo
    levelDetailBack:null,//等级详情背景
    growthBack:null,//成长值背景
    growthProcessBack: null,//成长值进度条背景
    initGrowthProcessBack: null,//初始化成长值进度条背景
    growthLogo:null,//成长值logo
    state: 0, //默认未启用
    uploadMemberRightIconList: null,
    cardList: [{
      iconUrl: "", // icon
      iconList: "", // icon
      isIssue: null
    }],
    couponList: [{
      iconUrl: "", // icon
      iconList: "", // icon
      isIssue: null
    }],
    list: [{
      iconUrl: "", // icon
      iconList: "", // icon
      isIssue: null
    }] //等级对应的权益信息
  };


  //规则校验
  memberLevelFormDataRules = {
    level: [{ required: true, message: "请输入等级", trigger: "blur" }],
    levelName: [{ required: true, message: "请输入等级名称", trigger: "blur" }],
    beginGrowth: [{ required: true, message: "请输入开始经验值", trigger: "blur" }],
    endGrowth: [{ required: true, message: "请输入终止经验值", trigger: "blur" }],
    list: [
      { required: true, message: "请添加对应的权益信息", trigger: "blur" },
      { min: 1, message: "请添加对应的权益信息", trigger: "blur" }
    ]
  };

  defaultSmallLevelLogoList: any = [];
  defaultLevelLogoList: any = [];
  defaultLevelBackList: any = [];
  defaultLevelDetailLogoList: any = [];
  defaultLevelDetailBackList: any = [];
  defaultGrowthLogoList: any = [];
  defaultGrowthBackList: any = [];
  defaultGrowthProcessBackList: any = [];
  defaultInitGrowthProcessBackList: any = [];


  handleCouponRightsIconUploadSuccess(e,index) {
    let b = JSON.parse(JSON.stringify(this.memberLevelFormData));
    b.couponList[index].iconUrl = e;
    console.log("b.couponList[index].iconUrl:" + b.couponList[index].iconUrl);
    this.memberLevelFormData = b;
  }

  handleCardRightsIconUploadSuccess(e,index) {
    let a = JSON.parse(JSON.stringify(this.memberLevelFormData));
    a.cardList[index].iconUrl = e;
    this.memberLevelFormData = a;
  }


  dayjs = dayjs;
  showCouponSelector: boolean = false;
  showCouponPopup: boolean = false;
  storeList: any = [];
  storeCouponList: any = [];

  get breadData() {
    return [
      { name: "等级配置", path: "/user/member-level-list" },
      { name: this.$route.name === "userMemberLevelEdit" ? "修改等级" : "添加等级" }
    ];
  }
  searchForm = {
    cardTemplateName: "",
    enableStatus: 1, //（1：启用、0：禁用） 只显示启用的卡
    storeCode: ''
    // cardType: 1 //（1：套餐卡、2：储蓄卡） 0630这个版本先写死
  };
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
      }
    });
    this.loadListData();
  }
  handleSearchCoupon(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
      }
    });
    this.storeCouponListFn();
  }
  selectedRow: any = [];
  selectedRowCoupon: any = [];
  confirmSelecteRow: any = [];
  confirmSelecteCoupon: any = [];

  //套餐卡选择
  handleRowSelect(select,rows) {
    if(this.selectedRow.length>0){
      let isPush = this.selectedRow.some(item => {
        return rows.cardTemplateCode == item.cardTemplateCode
      })
      if(!isPush){
        this.selectedRow.push(rows)
      }
    }else{
      this.selectedRow.push(rows)
    }
  }
  //优惠券选择
  handleRowSelectCoupon(select,rows){
    if(this.selectedRowCoupon.length>0){
      let isPush = this.selectedRowCoupon.some(item => {
        return rows.activityCode == item.activityCode
      })
      if(!isPush){
        this.selectedRowCoupon.push(rows)
      }
    }else{
      this.selectedRowCoupon.push(rows)
    }
  }

  //套餐卡选择确定
  handleSelectedCoupon() {
    if (this.selectedRow.length<1) {
      return this.$message.error("请选择一个卡模板");
    }

    this.selectedRow.some(item => {
      this.confirmSelecteRow.push({
        rightsName: item.cardTemplateName,
        rightsCode: item.cardTemplateCode,
        tenantCode: "SYS_DEFAUT",
        orgCode: "SYS_DEFAUT",
        rightsType:"20",
        isIssue:1,
        quantity: 1,
        iconUrl: ""
      });
    });
    if (this.isAdd) {
      this.memberLevelFormData.cardList = this.confirmSelecteRow;
    }
    this.showCouponSelector = false;
  }

  // 优惠券选择
  handleSelectedStoreCoupon() {
    console.log(this.selectedRowCoupon);
    if (this.selectedRowCoupon.length < 1) {
      return this.$message.error("请选择一张优惠券");
    }

    this.selectedRowCoupon.some(item => {
      this.confirmSelecteCoupon.push({
        rightsName: item.activityName,
        rightsCode: item.activityCode,
        tenantCode: item.tenantCode,
        orgCode: item.orgCode,
        rightsType: "21",
        isIssue: 1,
        quantity: 1,
        iconUrl: ""
      });
    });

    if (this.isAdd) {
      this.memberLevelFormData.couponList = this.confirmSelecteCoupon;
    }

    this.showCouponPopup = false;
  }
  get couponSearchList() {
    return [
    {
        label: t("v210801.card-template-name"),
        type: "input",
        value: "",
        prop: "cardTemplateName"
      },
    ];
  }
  get couponSearch() {
    return [
      {
        label: "选择门店",
        type: "select-search",
        value: this.searchForm.storeCode,
        prop: "storeCode",
        placeholder: "请选择门店",
        selectList: this.storeList,
        searchFun: this.searchStore
      }
    ];
  }
  get isAdd() {
    return this.$route.name === "userMemberLevelAdd";
  }
  created() {
    this.init();
  }
  get id() {
    return this.$route.params.id;
  }

  defaultBannerList: any = [];
  init() {
    console.log(this.id)
    this.searchStore();
    if (!this.isAdd) {
      console.log("这里是修改操作");
      this.getMemberLevelInfo({
        levelId: this.$route.params.id
      }).then(data => {
        this.memberLevelFormData = data.data;

        // 转换 isIssue 字段为字符串类型
        if (this.memberLevelFormData.list) {
          this.memberLevelFormData.list = this.memberLevelFormData.list.map(item => {
            return {
              ...item,
              iconList: item.iconUrl,
              isIssue: item.isIssue.toString()
            };
          });
        }
        this.memberLevelFormData.levelLogo = data.data.levelLogo;
        this.defaultSmallLevelLogoList = [{ url: this.memberLevelFormData.smallLevelLogo }];
        this.defaultLevelLogoList = [{ url: this.memberLevelFormData.levelLogo }];
        this.defaultLevelBackList = [{ url: this.memberLevelFormData.levelBack }];
        this.defaultLevelDetailLogoList = [{ url: this.memberLevelFormData.levelDetailLogo }];
        this.defaultLevelDetailBackList = [{ url: this.memberLevelFormData.levelDetailBack }];
        this.defaultGrowthLogoList = [{ url: this.memberLevelFormData.growthLogo }];
        this.defaultGrowthBackList = [{ url: this.memberLevelFormData.growthBack }];
        this.defaultGrowthProcessBackList = [{ url: this.memberLevelFormData.growthProcessBack }];
        this.defaultInitGrowthProcessBackList = [{ url: this.memberLevelFormData.initGrowthProcessBack }];


        //将数据赋给row
        this.confirmSelecteRow = data.data.cardList || [];
        this.confirmSelecteCoupon = data.data.couponList || [];
      });
    }
  }
  mounted() {}

  handleRemoveCard(index) {
    this.memberLevelFormData.cardList.splice(index, 1);
  }

  handleRemoveCoupon(index) {
    this.memberLevelFormData.couponList.splice(index, 1);
  }

  handleShowCard() {
    this.showCouponSelector = true;
    this.loadListData();
  }
  handleShowCoupon() {
    this.showCouponPopup = true;
  }

  // 获取门店列表
  searchStore(val = ''){
    console.log(val)
    this.getStoreList({
      storeTypeList: ['3', '8', '9'],
      pageNum: 1,
      pageSize: 10,
      storeName: val
    }).then(res => {
      this.storeList = res.data.list ? res.data.list.map(item => {
            item.label = item.storeName;
            item.value = item.storeCode;
            return item;
          })
        : [];
        this.searchForm.storeCode = this.storeList[0].storeCode
        this.storeCouponListFn();
    })
  }
  // 获取优惠券列表
  storeCouponListFn(){
    this.getStoreCouponList({
      storeCode: this.searchForm.storeCode,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(res => {
      console.log(res)
      this.storeCouponList = res.data.list
    })
  }

  couponList: any = [];
  @Table.LoadDataMethod()
  loadListData() {
    return this.queryCardTemplateList({
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.couponList = data.data.list;
      return data;
    });
  }


  submitMemberLevelForm(formName) {
    // console.log(JSON.stringify(this.memberLevelFormData));
    // console.log(JSON.stringify(this.confirmSelecteRow));
    // console.log(JSON.stringify(this.confirmSelecteCoupon));
    //
    // return;

    (this.$refs[formName] as any).validate(valid => {
      if (!valid) {
        return this.$message.error("请填写必填项");
      }
      // if (this.memberLevelFormData.endGrowth !== -1) {
      //   if (this.memberLevelFormData.endGrowth <= this.memberLevelFormData.beginGrowth) {
      //     return this.$message.error("终止经验值必须大于起始经验值");
      //   }
      // }

      MessageBox.confirm("请核对等级列表划是否正确？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        console.log("提交的信息:" + JSON.stringify(this.memberLevelFormData));
        if (this.isAdd) {
          this.addMemberLevelInfo(this.memberLevelFormData).then(() => {
            this.$message.success("添加成功");
            this.$router.push("/user/member-level-list");
          });
        } else {
          this.updateMemberLevelInfo(this.memberLevelFormData).then(() => {
            this.$message.success("修改成功");
            this.$router.push("/user/member-level-list");
          });
        }
      });
    });
  }
}
